var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SearchHistoryTableRow from './SearchHistoryTableRow.vue';
import { json } from 'overmind';
let SearchHistoryTable = class SearchHistoryTable extends Vue {
    constructor() {
        super(...arguments);
        this.stateInput = false;
    }
    remountComponent() { }
    getSearch(searchId) {
        return searchId;
    }
    getHistories(histories) {
        let data = json(histories);
        return data.reverse();
    }
    confirmRemove(id) {
        return id;
    }
};
__decorate([
    Prop({ default: () => [] })
], SearchHistoryTable.prototype, "histories", void 0);
__decorate([
    Emit('remountComponent')
], SearchHistoryTable.prototype, "remountComponent", null);
__decorate([
    Emit('getSearch')
], SearchHistoryTable.prototype, "getSearch", null);
__decorate([
    Emit('confirmRemove')
], SearchHistoryTable.prototype, "confirmRemove", null);
SearchHistoryTable = __decorate([
    Component({
        name: 'SearchHistoryTable',
        components: {
            SearchHistoryTableRow,
        },
    })
], SearchHistoryTable);
export default SearchHistoryTable;
