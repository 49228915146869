var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { connect } from '@/overmind';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import IconAutoPlay from './icons/IconAutoPlay.vue';
let SearchHistoryAutoPlayHeader = class SearchHistoryAutoPlayHeader extends Vue {
    constructor() {
        super(...arguments);
        this.credtisUserMonth = 0;
        this.credits_used = 0;
        this.loading = false;
    }
    get historiesCount() {
        return this.histories.length;
    }
    async mounted() {
        this.loading = true;
        //@ts-ignore
        this.credtisUserMonth = await this.userActions.getInfoAutoPlay();
        //@ts-ignore
        this.credits_used = this.credtisUserMonth.data.credits_used;
        this.loading = false;
    }
    async disableAllAutoplay() {
        //@ts-ignore
        await this.userActions.disableAllAutoplay();
        //@ts-ignore
        await this.actionsSearch.searchHistory.getSearchHistory();
        this.autoplayRemountComponent();
    }
    autoplayRemountComponent() { }
    closeModal() {
        return true;
    }
};
__decorate([
    Prop({ default: () => { } })
], SearchHistoryAutoPlayHeader.prototype, "histories", void 0);
__decorate([
    Prop({ default: 0 })
], SearchHistoryAutoPlayHeader.prototype, "autoplayActiveCount", void 0);
__decorate([
    Emit('autoplayRemountComponent')
], SearchHistoryAutoPlayHeader.prototype, "autoplayRemountComponent", null);
__decorate([
    Emit('closeModal')
], SearchHistoryAutoPlayHeader.prototype, "closeModal", null);
SearchHistoryAutoPlayHeader = __decorate([
    Component(connect(({ state, actions }) => ({
        userActions: actions.users,
        showAutoPlay: state.search.showAutoPlay,
        actionsSearch: actions.search,
    }), {
        name: 'SearchHistoryAutoPlayHeader',
        components: {
            IconAutoPlay,
        },
    }))
], SearchHistoryAutoPlayHeader);
export default SearchHistoryAutoPlayHeader;
