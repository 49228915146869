var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Input from 'components/Input.vue';
import Tooltip from 'components/Tooltip.vue';
import { mixpanelTracking } from '@/services/mixpanel';
import { connect } from '@/overmind';
let SearchExportModalStats = class SearchExportModalStats extends Vue {
    constructor() {
        super(...arguments);
        this.remaining = this.credits.remaining;
        this.selectedCount = this.valueExport || 0;
        this.waitingCreditPurchaseResult = false;
    }
    onExportCreditsChange(credits) {
        this.remaining = this.credits.remaining - this.valueExport;
    }
    onSelectedCountChange(newCount) {
        // @ts-ignore
        const limit = this.searchSummary.totalResult;
        if (limit && newCount > limit) {
            // verifica se o numero do input ultrapassa a quantidade de empresas da busca
            this.selectedCount = limit;
        }
        this.remaining = this.credits.remaining - newCount;
    }
    async mounted() {
        if (this.insufficientCredits)
            await this.tryImportWithoutCredits();
    }
    get exportCreditProgress() {
        return Math.abs(this.remaining / this.credits.value);
    }
    get insufficientCredits() {
        return (Number(this.selectedCount) > this.credits.remaining ||
            this.credits.remaining === 0);
    }
    get amountOfCreditsToBuy() {
        return Math.abs(this.remaining);
    }
    addExportCount() {
        if (this.selectedCount < 9999) {
            this.selectedCount = Number(this.selectedCount) + 1;
            this.$emit('setReturnSize', this.selectedCount);
        }
    }
    subtractExportCount() {
        if (this.selectedCount > 0) {
            this.selectedCount = Number(this.selectedCount) - 1;
            this.$emit('setReturnSize', this.selectedCount);
        }
    }
    inputExportCount() {
        let count = +(this.selectedCount + '').replaceAll(/[^0-9]/g, ''); //previne que qualquer entrada seja numerica
        this.selectedCount = count;
        this.$emit('setReturnSize', this.selectedCount);
    }
    async sendCreditPurchaseRequest() {
        if (this.waitingCreditPurchaseResult)
            return;
        this.waitingCreditPurchaseResult = true;
        mixpanelTracking('exportação interna: Solicitou compra de créditos');
        try {
            await Vue.axios.post('/account_plan/notify_change_plan_solicitation', {
                type: 'credit_purchase',
            });
            this.$q.notify({
                message: 'Recebemos sua solicitação de compra de créditos. Nosso time entratá em contato com você em breve!',
                type: 'success',
            });
        }
        catch (e) {
            this.$q.notify({
                message: 'Ocorreu um problema em sua solicitação. Entre em contato com o suporte',
                type: 'error',
            });
        }
        this.waitingCreditPurchaseResult = false;
    }
    async tryImportWithoutCredits() {
        // @ts-ignore
        await this.actions.tryToImportWithoutCredits();
    }
};
__decorate([
    Prop({ default: '' })
], SearchExportModalStats.prototype, "exportType", void 0);
__decorate([
    Prop({ default: () => [] })
], SearchExportModalStats.prototype, "selectedCompanies", void 0);
__decorate([
    Prop({ default: { value: 0, remaining: 0 } })
], SearchExportModalStats.prototype, "credits", void 0);
__decorate([
    Prop({ default: 0 })
], SearchExportModalStats.prototype, "valueExport", void 0);
__decorate([
    Watch('credits')
], SearchExportModalStats.prototype, "onExportCreditsChange", null);
__decorate([
    Watch('selectedCount')
], SearchExportModalStats.prototype, "onSelectedCountChange", null);
SearchExportModalStats = __decorate([
    Component(connect(({ state, actions }) => ({
        users: state.users.users,
        actions: actions.users,
        searchSummary: state.search.summary,
    }), {
        name: 'SearchExportModalStats',
        components: {
            Input,
            Tooltip,
        },
    }))
], SearchExportModalStats);
export default SearchExportModalStats;
