var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-list',_vm._l((_vm.handleFilteredFilters()),function(filter,filterIndex){return _c('q-expansion-item',{key:filterIndex,staticClass:"border-bottom",attrs:{"data-cy":"search-advanced-filters-decisors-panels","group":"decisionMakers","expand-separator":"","expand-icon-class":"q-pr-none"},on:{"before-hide":function($event){return _vm.onHideFilter(filterIndex)},"show":function($event){return _vm.onShowFilter(filterIndex, filter.title)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row full-width items-center"},[_c('span',{staticClass:"q-mr-sm font-12 text-grey-7"},[_vm._v("\n          "+_vm._s(_vm.handleTitle(filter.title))+"\n        ")]),(_vm.selectedCount(filter) === 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n          ("+_vm._s(_vm.selectedCount(filter))+" selecionado)\n        ")]):(_vm.selectedCount(filter) > 1)?_c('span',{staticClass:"text-primary text-weight-bold font-12"},[_vm._v("\n          ("+_vm._s(_vm.selectedCount(filter))+" selecionados)\n        ")]):_vm._e()])]},proxy:true}],null,true)},[_c('q-card',[_c('q-card-section',{staticClass:"q-pb-xs q-pt-sm",style:(filter.queryField.match('department')
            ? 'background-color: #f9fafb'
            : '')},[_c('div',{staticClass:"column"},[(filter.queryField == 'decision_makers_lkd_m5.department')?_c('div',{staticClass:"font-14 text-weight-400 q-mb-md q-mt-lg",staticStyle:{"color":"#5f6a6c"}},[_vm._v("\n            Departamento do decisor\n          ")]):_vm._e(),(
              ((_vm.fullTextTerm === '' || _vm.fullTextTerm == null) &&
                filter.filterOptions.length > 5) ||
              _vm.searchTerm
            )?_c('div',{staticClass:"col"},[_c('Input',{ref:"searchInput",refInFor:true,staticClass:"q-mb-sm",attrs:{"dense":"","rounded":"","outlined":"","placeholder":"Digite aqui...","debounceTime":200},on:{"input":function($event){return _vm.onSearchFilters(filter, $event)}},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1):_vm._e(),(filter.queryType === 'multiSelect')?_c('div',{staticClass:"col q-mb-sm"},[_c('q-virtual-scroll',{staticClass:"overflow-auto",style:(filter.queryField == 'decision_makers_lkd_m5.department'
                  ? 'max-height: 130px'
                  : 'max-height: 240px'),attrs:{"items":filter.filterOptions,"virtual-scroll-slice-size":10,"virtual-scroll-item-size":40},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [_c('div',{key:index,staticClass:"col"},[_c('q-checkbox',{attrs:{"label":_vm.formatDecisionMakerLabel(item.label),"value":item.isSelected},on:{"input":function($event){return _vm.setCheckbox(
                        _vm.filterGroupId,
                        filter.id,
                        filterIndex,
                        index,
                        $event,
                        item.value,
                        'departamento'
                      )}}}),(item.info)?_c('span',{staticClass:"q-ml-sm"},[_c('q-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"icon-info-new","color":"grey-6","size":"14px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_c('strong',[_vm._v(_vm._s(item.info))])])],1)],1):_vm._e()],1)]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"row align-center"},[(filter.queryField.match('department'))?_c('svg',{staticClass:"col-auto q-mr-md",staticStyle:{"margin-top":"25px"},attrs:{"width":"25","height":"1","viewBox":"0 0 25 1","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"0.5","y1":"0.5","x2":"24.5","y2":"0.5","stroke":"#EAECF0"}})]):_vm._e(),_c('div',{staticClass:"col-10"},[(
                  filter.queryField == 'decision_makers_lkd_m5.department'
                )?_c('div',{staticClass:"row justify-center q-mt-md q-pa-none align-center"},[_c('div',{staticClass:"col-5 font-14 q-mr-md",staticStyle:{"color":"#5f6a6c"}},[_vm._v("\n                  Departamento do decisor\n                ")]),_c('div',{staticClass:"col-auto cursor-pointer",staticStyle:{"display":"flex","margin-top":"-8px"}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectFilteredType),expression:"selectFilteredType"}],attrs:{"name":"opti"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.selectFilteredType=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){return _vm.setSelectFilteredType(_vm.selectFilteredType)}]}},_vm._l((_vm.optionsFilteredType),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v("\n                      "+_vm._s(option)+"\n                    ")])}),0)]),_c('div',{staticClass:"col-4 font-14 q-ml-md",staticStyle:{"color":"#5f6a6c"}},[_vm._v("\n                  nível do decisor\n                ")]),(
                    filter.queryField == 'decision_makers_lkd_m5.department'
                  )?_c('q-icon',{staticClass:"col q-ml-sm",staticStyle:{"margin-top":"2px","margin-left":"-20px"},attrs:{"name":"icon-info-new","color":"grey-6","size":"16px"}},[_c('q-tooltip',{attrs:{"anchor":"top middle","self":"bottom middle","content-style":"font-size:14px; background-color:#9EB3DC; width:318px; padding:.8rem;"}},[_vm._v("\n                    Utilizando OU, sua busca trará empresas com decisores que\n                    atendam a pelo menos um dos dois critérios determinados.\n                    Utilizando E, sua busca trará empresas com decisores do\n                    departamento e do nível que você selecionou, atendendo\n                    ambos os critérios.\n                  ")])],1):_vm._e()],1):_vm._e()]),(filter.queryField.match('department'))?_c('svg',{staticClass:"col-auto q-ml-sm",staticStyle:{"margin-top":"25px"},attrs:{"width":"25","height":"1","viewBox":"0 0 25 1","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"0.5","y1":"0.5","x2":"24.5","y2":"0.5","stroke":"#EAECF0"}})]):_vm._e()]),(filter.queryField == 'decision_makers_lkd_m5.department')?_c('div',{staticClass:"font-14 text-weight-400 q-mb-sm",staticStyle:{"color":"#5f6a6c","margin-top":"-40px"}},[_c('div',{staticClass:"q-mb-md q-ml-xs"},[_vm._v("Nível do decisor")]),_vm._l((_vm.handleLevelDecisors()),function(itemfilter,index_){return _c('div',{key:index_,staticClass:"col"},[(itemfilter)?_c('q-virtual-scroll',{staticClass:"overflow-auto",attrs:{"items":itemfilter.filterOptions,"virtual-scroll-slice-size":10,"virtual-scroll-item-size":40},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var item = ref.item;
                  var index = ref.index;
return [(item)?_c('div',{key:index,staticClass:"col"},[_c('q-checkbox',{attrs:{"label":_vm.formatDecisionMakerLabel(item.label),"value":item.isSelected},on:{"input":function($event){return _vm.setCheckbox(
                          _vm.filterGroupId,
                          itemfilter.id,
                          1,
                          index,
                          $event,
                          item.value,
                          'nível'
                        )}}})],1):_vm._e()]}}],null,true)}):_vm._e()],1)})],2):_vm._e(),(
              filter.queryField.match('department') &&
              _vm.handlePreVisualization()
            )?_c('div',{staticClass:"column q-mt-md q-mb-md",staticStyle:{"width":"440px","min-height":"108px","border-radius":"8px","border":"1px solid #ecf0f9","background-color":"#ffffff","padding":"20px"}},[_c('div',{staticClass:"row font-14 q-mr-xs",staticStyle:{"font-weight":"500","color":"#344054"}},[_vm._v("\n              Pré-visualização do filtro composto\n            ")]),_c('div',{staticClass:"row q-mt-sm font-14",staticStyle:{"font-family":"sans-serif","color":"#7d828a","font-weight":"400"}},[_vm._v("\n              Decisores \n              "),(_vm.department.length > 0)?_c('div',{staticClass:"col-auto font-14",staticStyle:{"font-family":"sans-serif","color":"#7d828a"}},[_vm._v("\n                do departamento\n                "),_c('strong',{staticStyle:{"font-weight":"600","color":"#344054"}},[_vm._v(_vm._s(_vm.department.join(', ')))])]):_vm._e(),(_vm.department.length > 0 && _vm.level.length > 0)?_c('div',{staticClass:"col-auto text-uppercase q-ml-sm q-mr-sm",staticStyle:{"color":"#2e90fa","background-color":"#2e90fa33","border-radius":"5px","width":"37px","height":"20px","font-weight":"700","text-align":"center"}},[_vm._v("\n                "+_vm._s(_vm.selectedFilteredType)+"\n              ")]):_vm._e(),(_vm.level.length > 0)?_c('div',[_vm._v("\n                de nível\n                "),_c('strong',{staticClass:"font-14 q-ml-xs",staticStyle:{"font-family":"sans-serif","color":"#344054","font-weight":"600"}},[_vm._v("\n                  "+_vm._s(_vm.level.join(', '))+"\n                ")])]):_vm._e()])]):_vm._e(),(filter.queryType === 'inputText')?_c('div',{staticClass:"col"},[_c('Input',{staticClass:"q-mb-sm q-mt-sm",attrs:{"dense":"","rounded":"","outlined":"","debounceTime":500},on:{"input":function($event){return _vm.setInputFilter({
                  queryField: filter.queryField,
                  value: $event,
                })}},model:{value:(_vm.inputText),callback:function ($$v) {_vm.inputText=$$v},expression:"inputText"}})],1):_vm._e()])])],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }