var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue, Prop, Emit, PropSync, Watch, } from 'vue-property-decorator';
import { connect } from '@/overmind';
import SearchHistoryRemoveModal from './SearchHistoryRemoveModal.vue';
import SettingsAutoplayModal from '@/components/Autoplay/SettingsAutoPlayModal.vue';
import IconConfigAutoPlay from '@/components/Autoplay/icons/IconConfigAutoPlay.vue';
import IconSearch from '@/components/Autoplay/icons/IconSearch.vue';
import IconTrash from '@/components/Autoplay/icons/IconTrash.vue';
import { mixpanelTracking } from '@/services/mixpanel';
let SearchHistoryTableRow = class SearchHistoryTableRow extends Vue {
    constructor() {
        super(...arguments);
        this.credits = {};
        this.showSettingsAutoplay = false;
        this.showModalRemoveSearchHistory = false;
        this.result = {};
        this.cakd = false;
        this.originClick = '';
        this.showAvaliable = false;
    }
    async showSettingsAutoplayModal(value) {
        this.originClick = value;
        //@ts-ignore
        this.showAvaliable = await this.userActions.getShowAvaliableAutoplay();
        this.showSettingsAutoplay = true;
    }
    handleTotalResults(value) {
        return new Intl.NumberFormat('pt-BR', {
            minimumSignificantDigits: 1,
        }).format(value);
    }
    confirmRemove(id) {
        return id;
    }
    remountComponent() { }
    closeSettingsAutoplayModal() {
        if (this.history.autoplayId === '')
            this.toggleIsActive = false;
        return false;
    }
    getSearch(searchId) {
        return searchId;
    }
    async toggleAutoplayActive(SaveConfigToggleClicked = false) {
        let toggle;
        if (SaveConfigToggleClicked) {
            if (!this.toggleIsActive) {
                toggle = !this.toggleIsActive;
            }
            else
                return;
        }
        else {
            toggle = !this.toggleIsActive;
        }
        if (this.history.autoplayId && this.history.autoplayId.length > 0) {
            //@ts-ignore
            await this.userActions.toggleAutoplayActive({
                value: toggle,
                history_search_id: this.history.id,
            });
            if (toggle)
                this.showSettingsAutoplayModal('toggle');
            this.remountComponent();
            return;
        }
        else if (toggle) {
            this.showSettingsAutoplayModal('toggle');
            this.toggleIsActive = toggle;
        }
    }
    trackingAutoPlayIsActive() {
        if (this.toggleIsActive) {
            mixpanelTracking('autoplay: autoplay foi ativado em uma lista');
        }
        else {
            mixpanelTracking('autoplay: autoplay foi desativado em uma lista');
        }
    }
};
__decorate([
    Prop({ default: () => { } })
], SearchHistoryTableRow.prototype, "history", void 0);
__decorate([
    PropSync('toggle', { default: false })
], SearchHistoryTableRow.prototype, "toggleIsActive", void 0);
__decorate([
    Emit('confirmRemove')
], SearchHistoryTableRow.prototype, "confirmRemove", null);
__decorate([
    Emit('remountComponent')
], SearchHistoryTableRow.prototype, "remountComponent", null);
__decorate([
    Emit('getSearch')
], SearchHistoryTableRow.prototype, "getSearch", null);
__decorate([
    Watch('toggleIsActive')
], SearchHistoryTableRow.prototype, "trackingAutoPlayIsActive", null);
SearchHistoryTableRow = __decorate([
    Component(connect(({ state, actions }) => ({
        userActions: actions.users,
        accountCredits: state.users.accountCredits,
        actionsSearch: actions.search,
        showAutoPlay: state.search.showAutoPlay,
    }), {
        name: 'SearchHistoryTableRow',
        components: {
            SearchHistoryRemoveModal,
            SettingsAutoplayModal,
            IconConfigAutoPlay,
            IconSearch,
            IconTrash,
        },
    }))
], SearchHistoryTableRow);
export default SearchHistoryTableRow;
